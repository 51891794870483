/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-param-reassign */

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { object, string } from 'prop-types';
import { compose } from 'recompose';
import loadable from '@loadable/component';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { getPresentationFamily, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import * as app from '../../../../state/ducks/App';
import { GRAPHQL_ENV } from '../../../gql';
import HomePageSkeleton from '../GraphqlSkeletonComponents/HomePageSkeleton';
import GraphqlDepartmentPageBody from './GraphqlDepartmentPageBody';
import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import isSingleSource from '../../../helpers/isSingleSource';

const GraphqlBreadcrumbs = loadable(() => import(/* webpackChunkName: "GraphqlBreadcrumbs" */ '../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs'));
const GraphqlSeoHeadTags = loadable(() => import(/* webpackChunkName: "GraphqlSeoHeadTags" */ '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags'));
const Graphql404ErrorContainer = loadable(() => import(/* webpackChunkName: "Graphql404ErrorContainer" */ '../Graphql404Error/Graphql404ErrorContainer'));

const appSelectors = app.selectors;
const brandSelectors = app.ducks.brand.selectors;

const useStyles = makeStyles((theme) => ({
    departmentBreadCrumb: {
        display: 'block',
        maxWidth: '1400px',
        width: '100%',
        margin: '0 auto',
        padding: '10px 0px',
        [theme.breakpoints.down(1025)]: {
            padding: '0px 0px 8px 10px',
        },
    },
    departmentBreadCrumbWithGrid: {
        margin: 'auto auto -2rem auto',
    },
    mobileBreadCrumb: {
        padding: '20px 0 0 20px',
    },
    homePageWithRowGap: {
        display: 'grid',
        rowGap: '2rem',
        gridTemplateColumns: '100%',
    },
}));

const GraphqlDepartmentPage = ({
    brand, page, ssrDeviceType, presentationFamily,
}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width: 600px)');
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    let isCelebrationsLander = false;

    const useSingleSource = isSingleSource(page?.type, page?.path) || {};
    let altPath = '';
    if (useSingleSource !== undefined && useSingleSource.brand) {
        brand = useSingleSource.brand;
        altPath = useSingleSource.path;
        page.content_type = useSingleSource.content_type;
        isCelebrationsLander = useSingleSource.celebrations?.['is-celebrations-lander'] || false;
    }

    const DEPARTMENTPAGE_QUERY = gql`
    query DepartmentPageQuery {
        findContentPage(brand: "${brand['domain-name']}", url: "${page?.path}", contentType: "${page?.contentType || 'department'}", environment: "${GRAPHQL_ENV}", locale: "en-us", url: "${altPath || page?.path || '/'}",query:"{\\"include_dimension\\":true}") {
            content
        }
    }
    `;

    const QUERY = DEPARTMENTPAGE_QUERY;
    return (
        <Query query={QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <HomePageSkeleton />; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: DEPARTMENTPAGE_QUERY,
                        component: 'GraphqlDepartmentPage.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: DEPARTMENTPAGE_QUERY,
                        component: 'GraphqlDepartmentPage.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });

                    return <Graphql404ErrorContainer brand={brand} page={page} />;
                }
                if (!data.findContentPage?.content) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: DEPARTMENTPAGE_QUERY,
                        data,
                        component: 'GraphqlDepartmentPage.js',
                        message: 'No content returned for query',
                    });
                    return <Graphql404ErrorContainer brand={brand} page={page} />;
                }

                const pageBlocks = data.findContentPage.content.entries?.[0]?.page_blocks || null;
                if (!pageBlocks) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: DEPARTMENTPAGE_QUERY,
                        data,
                        component: 'GraphqlDepartmentPage.js',
                        message: 'No page blocks for query',
                    });
                    return <Graphql404ErrorContainer brand={brand} page={page} />;
                }

                const untData = data.findContentPage.underNavText?.entries || null;
                const SEO = data.findContentPage.content.entries?.[0]?.seo || null;
                const domainURL = `https://pwa.${brand.domain}.com` || '';

                // showng breadcrumb department
                const breadcrumbDepartment =  () => (
                    <GraphqlBreadcrumbs breadCrumbArray={SEO.breadcrumb} />
                );
                const isFlowerDesktop = presentationFamily === 'flower' && !isMobile;
                const isFlowerMobile = presentationFamily === 'flower' && isMobile;
                return (
                    <div data-pagetype="department" className={ffHasHomepageGridStructure ? classes.homePageWithRowGap : ''}>
                        {SEO?.breadcrumb?.length > 0 && (presentationFamily === 'food' || isFlowerDesktop) && (
                            <div className={`${classes.departmentBreadCrumb} ${ffHasHomepageGridStructure && classes.departmentBreadCrumbWithGrid}`}>
                                {breadcrumbDepartment()}
                            </div>
                        )}
                        <GraphqlSeoHeadTags seoData={SEO} />
                        <GraphqlDepartmentPageBody
                            brand={brand}
                            page={page}
                            blocks={pageBlocks}
                            untData={untData}
                            seo={SEO}
                            domainURL={domainURL}
                            ssrDeviceType={ssrDeviceType}
                            presentationFamily={presentationFamily}
                            isCelebrationsLander={isCelebrationsLander}
                        />
                        {SEO?.breadcrumb?.length > 0 && isFlowerMobile && (
                            <div className={classes.mobileBreadCrumb}>
                                {breadcrumbDepartment()}
                            </div>
                        )}
                    </div>
                );
            }}
        </Query>
    );
};

GraphqlDepartmentPage.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
    ssrDeviceType: appSelectors.getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
);

export default enhance(GraphqlDepartmentPage);
