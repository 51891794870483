/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../state/ducks/App/ducks/Config/Config-Selectors';

const isSingleSource = (template, path) => {
    if (template === undefined || !template || path === undefined || !path) return {};
    const variantObj = useSelector(getFeatureFlag('is-template-data-source-from-single-stack') || false);
    const obj = {
        brand: '', path: '', content_type: '', celebrations: {},
    };
    if (variantObj !== undefined && variantObj) {
        Object.entries(variantObj).forEach(([key, value]) => {
            if (template === key) {
                Object.entries(value).forEach(([templatekey, templatevalue]) => {
                    if (path === templatekey) {
                        obj.brand = {
                            domain: templatevalue['source-stack-domain'],
                            'domain-name': templatevalue['source-stack-domain'],
                        };
                        obj.path = templatevalue['source-path'];
                        obj['content_type'] = template;
                        obj.celebrations = templatevalue['celebrations'];
                    }
                });
            }
        });
        return obj;
    } return {};
};

export default isSingleSource;
