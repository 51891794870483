/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { object, shape, string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Reply from '@material-ui/icons/Reply';
import DesktopMobile from '../../../../../helpers/DesktopMobile/DesktopMobile';

const styles = () => ({
    cardContainer: {
        width: '100%',
        maxWidth: '1100px',
        margin: '50px auto 80px',
        '@media screen and (max-width: 980px)': {
            margin: '0 auto 80px',
        },
    },
    cards: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '250px',
        maxHeight: '450px',
        '@media screen and (min-width: 989px)': {
            '&> div:last-of-type': {
                marginRight: '0',
            },
        },
        userSelect: 'none',
        outline: 'none',

        '@media screen and (min-width: 768px) and (max-width: 980px)': {
            justifyContent: 'space-evenly',
            '&> div:last-of-type': {
                display: 'none',
            },
            '&> div:not(first-of-type)': {
                marginRight: '0',
            },
        },
        '@media screen and (min-width: 500px) and (max-width: 767px)': {
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            alignItems: 'center',
            '&> div:last-of-type': {
                margin: '50px auto',
            },
        },
    },

    card: {
        position: 'relative',
        marginRight: '54px',
        width: '100%',
        maxWidth: '372px',
        minWidth: '25%',
        height: '325px',
        cursor: 'pointer',
        perspective: '150em',
        userSelect: 'none',
        outline: 'none',
        '@media screen and (min-width: 500px) and (max-width: 980px)': {
            width: '325px',
            minWidth: 'auto',
        },
    },
    card_active: { // when added to card, instantiates flip
        '& > div': {
            transform: 'rotateY(-180deg)',
        },
        '& > div + div': {
            transform: 'rotate(0)',
        },
        '& svg': {
            opacity: 0,
        },
        transition: 'all 0.3s ease-in-out',
    },
    card_side: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '325px',
        borderRadius: '8px',
        backfaceVisibility: 'hidden',
        transition: 'all 0.6s ease-in-out',
        boxShadow: '0 4px 12px rgb(0 0 0 / 19%)',
        userSelect: 'none',
        outline: 'none',
    },

    card_back: {
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        boxSizing: 'border-box',
        padding: '20px 35px',
        transform: 'rotateY(-180deg)',
        '& p': {
            fontSize: '.95em',
        },
    },
    card_face: {
        display: 'flex',
        alignItems: 'flex-end',
    },

    face_title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '60px',
        background: 'rgba(255,255,255, .80)',
        color: '#000',
        textAlign: 'center',
        borderRadius: '0 0 8px 8px',
    },

    cardsMob: {
        justifyContent: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
        margin: '0 auto 50px',
        height: 'auto',
        maxHeight: 'none',
    },
    cardMob: {
        margin: '44px auto 0',
        width: 'calc(100vw - 40px)',
        height: '325px',
        '& >div > div:first-of-type': {
            position: 'relative',
            height: '100px',
        },
    },
    flipArrow: {
        transform: 'rotateY(-180deg)',
        alignSelf: 'flex-end',
        transition: 'opacity .6s ease',
    },
    mobcta: {
        position: 'absolute',
        bottom: '10px',
        right: '5px',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '.7em',
    },
});

class Cards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 9999,
        };
    }

    onFlipCard(i) {
        const { active } = this.state;
        this.setState({ active: i !== active ? i : null });
    }

    render() {
        const { data, classes } = this.props;
        const { active } = this.state;
        const cards = data.card;
        return (
            <div className={classes.cardContainer}>
                <DesktopMobile
                    desktop={() => (
                        <div className={classes.cards}>
                            {
                                cards.map((card, i) => (
                                    <div
                                        className={`${classes.card} ${(active === i) && classes.card_active}`}
                                        onMouseEnter={() => this.onFlipCard(i)}
                                        onKeyDown={() => this.onFlipCard(i)}
                                        onTouchEnd={() => this.onFlipCard(i)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div className={`${classes.card_side} ${classes.card_face}`} style={{ background: `url(${card.face_image?.url}) no-repeat bottom right` }}>
                                            <div className={classes.face_title}>{card?.face_title}</div>
                                        </div>
                                        <div className={`${classes.card_side} ${classes.card_back}`} style={{ background: `url(${card.card_bg_image?.url}) no-repeat bottom right`, backgroundSize: 'cover' }}>
                                            <p>{card.card_copy}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    mobile={() => (
                        <div
                            className={`${classes.cards} ${classes.cardsMob}`}
                        >
                            {
                                cards.map((card, i) => (
                                    <div
                                        className={`${classes.card} ${classes.cardMob} ${(active === i) ? classes.card_active : ''}`}
                                        onKeyDown={() => this.onFlipCard(i)}
                                        onTouchStart={() => this.onFlipCard(i)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div className={`${classes.card_side} ${classes.card_face}`} style={{ background: `url(${card.face_image?.url}) no-repeat bottom right` }}>
                                            <div className={classes.face_title}>{card?.face_title}</div>
                                            <div className={classes.mobcta}><Reply className={classes.flipArrow} />Learn More</div>
                                        </div>
                                        <div className={`${classes.card_side} ${classes.card_back}`} style={{ background: `url(${card.card_bg_image?.url}) no-repeat bottom right`, backgroundSize: 'cover'  }}>
                                            <p>{card.card_copy}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )}
                    breakpoint={767}
                />
            </div>
        );
    }
}

Cards.propTypes = {
    data: shape({
        card_bg_image: shape({
            url: 'string',
        }),
        card_copy: string,
        face_image: shape({
            url: 'string',
        }),
        face_title: string,
    }),
    classes: object.isRequired,
};
Cards.defaultProps = {
    data: {
        card_bg_image: {
            url: '',
        },
        card_copy: '',
        face_image: {
            url: '',
        },
        face_title: '',
    },
};
export default withStyles(styles)(Cards);
