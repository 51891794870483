/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withRouter } from 'react-router';
import { useUIDSeed } from 'react-uid';
import {
    string, array, object, bool,
} from 'prop-types';
import { compose } from 'recompose';

import DepartmentPageBlock from './DepartmentPageBlock';
import RenderWhenVisible from '../../../helpers/componentVisibility/RenderWhenVisible';

const FOLD_INDEX = 4;

const GraphqlDepartmentBody = ({
    brand,
    ssrDeviceType,
    blocks,
    presentationFamily,
    isCelebrationsLander,
}) => {
    if (!blocks?.length) return null;
    const seed = useUIDSeed();
    return (
        <>
            {blocks.map((block, index) =>  (index <= FOLD_INDEX ? (
                <DepartmentPageBlock
                    brand={brand}
                    block={block}
                    key={seed(block)}
                    ssrDeviceType={ssrDeviceType}
                    presentationFamily={presentationFamily}
                    isCelebrationsLander={isCelebrationsLander}
                />
            ) : (
                <RenderWhenVisible>
                    <DepartmentPageBlock
                        brand={brand}
                        block={block}
                        key={seed(block)}
                        ssrDeviceType={ssrDeviceType}
                        presentationFamily={presentationFamily}
                        isCelebrationsLander={isCelebrationsLander}
                    />
                </RenderWhenVisible>
            )))}
        </>
    );
};

GraphqlDepartmentBody.propTypes = {
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    blocks: array,
    brand: object.isRequired,
    isCelebrationsLander: bool.isRequired,
};

GraphqlDepartmentBody.defaultProps = {
    blocks: [],
};

const enhance = compose(
    withRouter,
);

export default enhance(GraphqlDepartmentBody);
